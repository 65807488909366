<template>
  <v-card class="pa-6" color="surface">
    <v-card-title class="py-0 pl-0">
      <v-btn
        class="rounded-lg mr-3"
        tile
        icon
        @click="$router.push({ path: '/home' })"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-responsive max-width="180">
        <v-img
          :src="
            require(`@/assets/logo-${
              $vuetify.theme.dark ? 'white' : 'black'
            }.png`)
          "
          class="pointer-hand"
          @click="$router.push({ path: '/home' })"
        />
      </v-responsive>
    </v-card-title>

    <v-card-title class="pl-0 mb-0">Boas-vindas</v-card-title>

    <v-card-subtitle class="pl-0 text-body-1">
      Acesse sua conta, informe suas credenciais
    </v-card-subtitle>

    <!-- form -->
    <v-form v-model="valid" ref="form" class="mb-6" autocomplete="off">
      <div @keypress.enter.prevent="submit()" class="mb-2">
        <v-text-field
          v-model="form.email"
          label="E-mail"
          color="primary"
          :rules="rules"
          outlined
        />

        <v-text-field
          v-model="form.password"
          label="Senha"
          color="primary"
          type="password"
          :rules="rules"
          outlined
        />
      </div>

      <v-btn color="gradient" dark block large @click="submit()">
        Efetuar Acesso
      </v-btn>
    </v-form>

    <div align="center">
      Não possui uma conta?
      <a
        class="primary--text decoration"
        @click="$router.push({ path: '/auth/sign-up' })"
      >
        Criar agora
      </a>
    </div>

    <loader-hover v-if="loading"/>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import {signIn} from "@/services/auth";
import {displayAlert} from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      form: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setSignIn"]),

    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        // Redirect
        // const application = this.$store.getters.application
        // if (application && application.baseURL) {
        //   const callback = this.$route.query.callback || application.callback || ''
        //   const url = `${application.baseURL}${callback}`;
        //
        //   // Redirect
        //   return window.location.href = url + (url.includes("?") ? '&' : '?') + `token=${this.$store.getters.refreshToken}`
        // }

        // Local Login
        await signIn(this.form).then((res) => {
          this.setSignIn({
            user: res.body.user,
            token: res.body.token,
          });

          this.$router.push({path: "/home"});
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    displayAlert,
  },
};
</script>

<style lang="sass" scoped>
</style>